import React, { Fragment, useEffect, useState } from "react";
import PropTypes, { string } from "prop-types";
import { ErrorContainer } from "../dom/ErrorContainer";
import { KEYS } from "../enums/KeysIndexes";
import { SearchInputGroup } from "../dom/SearchInputGroup";
import { DropList } from "../dom/DropList";
import { Entities } from "../enums/Entities";
import { Utils } from "../Utils";

const axios = require('axios').default;

/**
 * компонент для поиска только по связанным с Persons сущностям - Students, Wishes, Teachers, Users
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const EntitiesSearchDropList = props => {

    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [resultCursor, setResultCursor] = useState(0);
    const [currentItemClicked, setCurrentListItemClicked] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
        if (!query) {
            setResults([]);
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            loadEntities();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    const loadEntities = () => {
        if (query.length <= 1) {
            return;
        }
        const url = '/api/' + props.entity + '/';
        let filter = getFiltersByEntity();
        axios.get(url, {
            params: filter,
        })
            .then((response) => {
                if (response.data.length > 0) {
                    setResults(response.data);
                } else {
                    setNoResults(true);
                }
            })
            .catch((error) => {
                setErrors(errors => [...errors, error.response.data]);
            });
    };

    const setResultsActiveItem = (keyCode) => {
        if (keyCode === KEYS.DOWN && results.length) {
            setResultCursor(prevState =>
                prevState < results.length ? prevState + 1 : prevState,
            );
        } else if (keyCode === KEYS.UP && results.length) {
            setResultCursor(prevState =>
                prevState > 0 ? prevState - 1 : prevState,
            );
        } else if (keyCode === KEYS.ENTER && results.length) {
            setCurrentListItemClicked(true);
        }
    };

    const getFiltersByEntity = () => {
        let filter = {};
        filter.filter = "Y";
        filter.limit = 30;
        filter["LOGIC OR"] = {};
        switch (props.entity) {
            case Entities.GROUPS:
                filter.active = "Y";
                filter["LOGIC OR"]["name LIKE"] = `%${query}%`;
                if (Utils.isInteger(query)) {
                    filter["LOGIC OR"]["ID"] = query;
                }
                filter["LOGIC OR"]["code LIKE"] = `%${query}%`;
                break;
            case Entities.PROGRAMS:
                filter["LOGIC OR"]["name LIKE"] = `%${query}%`;
                break;
            case Entities.CALCS:
                if (Utils.isInteger(query)) {
                    filter["LOGIC OR"]["ID"] = query;
                    filter["LOGIC OR"]["gID"] = query;
                    filter["LOGIC OR"]["year"] = query;
                    filter["LOGIC OR"]["month"] = query;
                }
                break;
            case Entities.CONTACT:
                filter["LOGIC OR"]["sID IN"] = `(SELECT ID FROM diary_students WHERE person_id IN (SELECT ID FROM diary_persons WHERE f LIKE '%${query}%'))`;
                filter["LOGIC OR"]["f LIKE"] = `%${query}%`;
                break;
            case Entities.CERTIFICATES:
                filter["LOGIC OR"]["number LIKE"] = `%${query}%`;
                filter["LOGIC OR"]["student_id IN"] = `(SELECT ID FROM diary_students WHERE person_id IN (SELECT ID FROM diary_persons WHERE f LIKE '%${query}%'))`;
                break;
        }
        return filter;
    };

    const getListItemView = (item) => {
        let entityString = `# ${item.ID || item.id}`;
        if (item.name) {
            entityString += `, ${item.name}`;
        }
        switch (props.entity) {
            case Entities.GROUPS:
                if (item.code) {
                    entityString += `, Код ${item.code}`;
                }
                if (item.date_start_display) {
                    entityString += `, ${item.date_start_display}`;
                }
                if (item.date_end_display) {
                    entityString += ` - ${item.date_end_display}`;
                }
                break;
            case Entities.CALCS:
                entityString += `, начисление по группе # ${item.gID}`;
                entityString += `, за ${item.month}.${item.year}`;
                break;
            case Entities.PROGRAMS:
                if (item.archive == "Y") {
                    entityString += `, (архивная)`;
                }
                break;
            case Entities.CONTACT:
                if (item.fio) {
                    entityString += `, ${item.fio}`;
                }
                if (item.student) {
                    entityString += `, (слушатель ${item.student.fio})`;
                }
                break;
            case Entities.CERTIFICATES:
                if (item.number) {
                    entityString += `. Сертификат ${item.number}`;
                }
                if (item.student) {
                    entityString += `, ${item.student.fio}`;
                }
                break;
        }
        return entityString;
    };

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors} /> : ""}
            <SearchInputGroup
                disabled={isLoading}
                isLoading={isLoading}
                value={query}
                onChange={(value) => setQuery(value)}
                onKeyDown={e => setResultsActiveItem(e.keyCode)}
                placeholder={props.placeholder ? props.placeholder : "быстрый поиск"}
                aria-label={props.placeholder ? props.placeholder : "быстрый поиск"} />
            <DropList
                resultCursor={resultCursor}
                currentItemClicked={currentItemClicked}
                noResults={noResults}
                getListItemView={getListItemView}
                results={results}
                entity={props.entity} />
        </Fragment>
    );
};

EntitiesSearchDropList.propTypes = {
    placeholder: PropTypes.string,
    entity: PropTypes.string,
};
