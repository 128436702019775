import React                    from "react";
import {Pages}                  from "./Pages";
import ReactDOM                 from "react-dom";
import {EntitiesSearchDropList} from "../components/EntitiesSearchDropList";
import {Entities}               from "../enums/Entities";

export class CalcsDetail extends Pages {
    constructor(params) {
        super(params);

    }

    initPageHandlers(params) {
        super.initPageHandlers(params);

    }
}
